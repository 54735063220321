import React from 'react';
import Layout from '../components/Layout';
import image from "../assets/images/ob-404.jpg" // Tell Webpack this JS file uses this image

const NotFoundPage = () => (
  <Layout darkText>
    <div className="page-content">
      <h1>It's cool, none of us know what we're doing either...</h1>
      <p>404 - Not a valid URL</p>
      <img src={image} style={{
        width: '100%'
      }} alt="Oil Bees racing crew chilling" />
    </div>
  </Layout>
);

export default NotFoundPage;
